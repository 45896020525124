export const METADATA_URIS_MAP = {
  [11155111]: {
    collectionsManager: 'https://pstlcollections.s3.eu-south-2.amazonaws.com/collections/'
  },
  [137]: {
    collectionsManager: 'https://pstlcollections.s3.eu-south-2.amazonaws.com/collections/'
  },
  [80002]: {
    collectionsManager: 'https://pstlcollections.s3.eu-south-2.amazonaws.com/collections/'
  }
} as const
